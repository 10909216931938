<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--<vuexy-logo />-->
        <cubus-logo />
        <h2 class="brand-text text-primary ml-1">
          <div style="color: darkred">
            Cubus
          </div>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <span v-if="lang === 'ru'">Добро пожаловать в Cubus! 👋</span>
            <span v-else>Welcome to Cubus! 👋</span>
          </b-card-title>
          <b-card-text class="mb-2">
            <span v-if="lang === 'ru'">Входите и наслаждайтесь работой</span>
            <span v-else>Please sign-in to your account and start the adventure</span>
          </b-card-text>

          <b-alert v-if="false"
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Guest:</span> guest | guest</small>
              </p>
              <!--<p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>-->
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top:10px; right:10px;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="lang === 'ru' ? ' Имя пользователя или е-мейл' : 'Username or Email'"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>
                    <span v-if="lang === 'ru'">Пароль</span>
                    <span v-else>Password</span>
                  </label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>
                      <span v-if="lang === 'ru'">Забыли пароль?</span>
                      <span v-else>Forgot Password?</span>
                    </small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  <span v-if="lang === 'ru'">Запомнить меня</span>
                  <span v-else>Remember Me</span>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <span v-if="lang === 'ru'">Войти</span>
                <span v-else>Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>
              <span v-if="lang === 'ru'">В первый раз здесь? </span>
              <span v-else>New on our platform? </span>
            </span>
            <b-link :to="{name:'auth-register'}">
              <span>
                <span v-if="lang === 'ru'">Создайте аккаунт</span>
                <span v-else>Create an account</span>
              </span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div v-if="false" class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div v-if="false" class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>

          <div class="demo-inline-spacing">
            <b-form-radio v-model="lang" name="ru" value="ru">
              <b-img v-if="false"
                  :src="ruLocale().img"
                  height="14px"
                  width="22px"
                  alt="ru"
              />  RU
            </b-form-radio>
            <b-form-radio v-model="lang" name="en" value="en">
              <b-img v-if="false"
                  :src="enLocale().img"
                  height="14px"
                  width="22px"
                  alt="en"
              />  EN
            </b-form-radio>
          </div>

          <div class="small text-muted" style="margin-top:2rem;">
            release: {{ release }}
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import CubusLogo from '@/cubus/CubusLogo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle,
  BImg, BForm, BButton, BAlert, VBTooltip, BFormRadio,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    // VuexyLogo,
    CubusLogo,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: 'admin',
      userDomain: 'domain',
      userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      release: '0000.0000.0000',
      lang: 'ru',
    }
  },
  created() {
    this.init()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Russian',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  methods: {
    init() {
      this.initRelease()
    },
    login() {
      // console.log('Login.login')
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            domain: this.userDomain,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log('login response', response)
              if (response.data.session) {
                useJwt.setCubusToken(response.data.session.token)
                this.$store.commit('cubus-store/SET_SESSION', response.data.session)

                const { userData } = response.data
                const sess = response.data.session
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update([{ action: 'manage', subject: 'all' }])

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 5)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser(sess.user.role.name))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${sess.user.name || sess.user.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${sess.user.role.name}. Now you can start to explore!`,
                      },
                    })
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Something went wrong',
                    icon: 'CoffeeIcon',
                    variant: 'warning',
                    text: response.data.status,
                  },
                })
              }
            })
            .catch(error => {
              console.log('login error', error)
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    initRelease() {
      this.release = useCubus.release()
    },
    ruLocale() {
      return this.locales.find(l => l.locale === 'ru')
    },
    enLocale() {
      return this.locales.find(l => l.locale === 'en')
    },
  },
}
</script>

<style lang="scss">
@import 'cbs-page-auth';
</style>
